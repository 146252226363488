import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/input/input.vue')
  },
  {
    path: '/template',
    name: 'Template',
    component: () => import('@/views/template/template.vue')
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import('@/views/category/category.vue'),
  },
  {
    path: '/result',
    name: 'Download',
    component: () => import('@/views/download/download.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
